function * range(start: number, end: number): IterableIterator<number> {
  yield start;

  if (start === end) {
    return;
  }

  if (start > end) {
    yield * range(start - 1, end);
  } else {
    yield * range(start + 1, end);
  }
}

export { range };
