






















































































































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import formatDate from 'date-fns/format';
import Vue from 'vue';
import { debounce as _debounce } from 'lodash';
import {
  addMonths,
  addYears,
  endOfDay,
  getDate, getDay,
  getDaysInMonth,
  getISODay,
  getMonth, getWeeksInMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  parse as parseDate,
  parseISO,
  startOfDay,
  startOfMonth,
  subMonths,
  subYears,
} from 'date-fns';
import {
  addTouchAndClickEventListenerToTarget,
  removeTouchAndClickEventListenerToTarget,
} from '@/utils/eventListener';
import { DEBOUNCE_WAIT_RESIZE } from '@/utils/globals';
import { deviceIsIPad } from '@/utils/userAgent';
import { mixinDropdownPositioning } from '@/components/mixins/dropdownPositioning';
import { POSITION } from '@/utils/positions';
import { range } from '@/utils/generators';
import { utIsEmpty } from '@/utils/empty';

export default Vue.extend({
  name: 'BaseDatePicker',

  components: {
    BaseSvgIcon,
    BaseInputField,
  },

  mixins: [
    mixinDropdownPositioning,
  ],

  props: {
    anchorData: {
      type: Array,
      default: function() {
        return [
          POSITION.CENTER,
        ];
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    displayFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    label: {
      type: [
        String,
        Object,
      ],
      default: null,
    },

    minHeight: {
      type: Number,
      default: 371,
    },

    notice: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'YYYY-MM-DD',
    },

    range: {
      type: Boolean,
      default: false,
    },

    value: {
      default: '',
      type: [
        Object,
        String,
      ],
    },
  },

  data() {
    return {
      debouncedPositionPicker: null,

      deviceIsIPad: deviceIsIPad(),

      firstDay: 1,

      hoveringOver: null,

      isOpen: false,

      listElementHeight: 340,

      month: 'January',

      numberOfDays: 30,

      position: {
        inputOne: {},
        inputTwo: {},
        picker: {},
      },

      selectingSecondDate: false,

      shortDays: this.$t('date.weekday.short'),

      viewDate: new Date(),

      year: 2000,
    };
  },

  computed: {
    coreDate(): Date {
      if (!this.range) {
        return parseISO(this.value);
      }

      return parseISO(this.value.from);
    },

    customAnchor(): HTMLElement | undefined {
      if (this.range) {
        return this.selectingSecondDate ? this.$refs?.inputTwo?.$el : this.$refs?.inputOne?.$el;
      }

      return this.$refs?.inputOne?.$el;
    },

    displayWeeks(): Array<any> {
      const result = [];

      const numOfWeeks = getWeeksInMonth(this.viewDate, {
        weekStartsOn: 1,
      });

      for (let i = 0; i < numOfWeeks; i++) {
        if (i === 0) {
          let gap = getDay(startOfMonth(this.viewDate)) - 1;

          if (gap === -1) {
            gap = 6;
          }

          result[0] = {
            gap,
            days: [
              ...range(1, 7 - gap),
            ],
          };
        } else {
          const lastWeeksDayList = result[i - 1].days;
          const lastDay = lastWeeksDayList[lastWeeksDayList.length - 1];
          const rangeEnd = getDaysInMonth(this.viewDate) < lastDay + 7
            ? getDaysInMonth(this.viewDate)
            : lastDay + 7;

          result[i] = {
            gap: 0,
            days: [
              ...range(lastDay + 1, rangeEnd),
            ],
          };
        }
      }

      return result;
    },

    inputOneValue: {
      get(): String {
        if (!this.range) {
          return utIsEmpty(this.value) ? '' : formatDate(parseISO(this.value, this.displayFormat), this.displayFormat);
        }

        return utIsEmpty(this.value.from) ? '' : formatDate(parseISO(this.value.from, this.displayFormat), this.displayFormat);
      },

      set(value: string): void {
        if (value === '') {
          const output = this.range ? {
            from: '', due: this.value.due,
          } : null;

          this.$emit('input', output);

          return;
        }

        try {
          const safeValue = value.replace(/-0\d/, match => match.replace('-0', '-'));

          const date = parseDate(safeValue, this.displayFormat, new Date()).toISOString();

          if (this.range) {
            this.$emit('input', {
              from: date,
              due: this.value.due,
            });

            this.viewDate = parseDate(safeValue, this.displayFormat, new Date());

            return;
          }

          this.$emit('input', date);
        } catch (error) {
          this.$logger.error(error);
        }
      },
    },

    inputTwoValue: {
      get() {
        return utIsEmpty(this.value.due) ? '' : formatDate(parseISO(this.value.due, this.displayFormat), this.displayFormat);
      },

      set(value: string): void {
        if (value === '') {
          this.$emit('input', {
            from: this.value.from,
            due: '',
          });

          return;
        }

        try {
          const safeValue = value.replace(/-0\d/, match => match.replace('-0', '-'));

          const date = parseDate(safeValue, this.displayFormat, new Date()).toISOString();

          this.$emit('input', {
            from: this.value.from,
            due: date,
          });

          this.viewDate = parseDate(safeValue, this.displayFormat, new Date());
        } catch (error) {
          this.$logger.error(error);
        }
      },
    },

    rangeEnd(): Date {
      if (!this.range) {
        return null;
      }

      return parseISO(this.value.due);
    },

    selectionStarted(): boolean {
      return !utIsEmpty(this.value.from) || !utIsEmpty(this.value.due);
    },

  },

  watch: {
    async isOpen(newValue): Promise<any> {
      if (newValue === true) {
        this.positionList();
      }
    },

    viewDate: {
      immediate: true,
      handler(): void {
        this.setViewData();
      },
    },
  },

  methods: {
    closeAndReposition(selection: { due: null; from: null }): void {
      this.selectingSecondDate = !utIsEmpty(selection.from);

      this.isOpen = utIsEmpty(selection.due) || utIsEmpty(selection.from);

      if (this.isOpen) {
        this.positionPicker();
      }
    },

    getDayClasses(day) {
      // Caching this should add a nice performance boost
      const coreDateDay = getDate(this.coreDate);
      const coreDateMonth = getMonth(this.coreDate);
      const monthInView = getMonth(this.viewDate);
      const fullDate = new Date(this.year, monthInView, day, 0, 0, 0, 0);
      const fullDateForHover = new Date(this.year, monthInView, this.hoveringOver, 0, 0, 0, 0);

      // Gotta use endOfDay method here because the setValidValuePair method is generating a
      // range end that has a time value of just before midnight
      const rangeEndDate = endOfDay(fullDate);

      if (!this.range) {
        return {
          'range-edge': day === coreDateDay && monthInView === coreDateMonth,
        };
      }

      const classList = {
        'range-edge': false,
        'range-member': false,
        'slice-and-dice': false,
      };

      if (this.hoveringOver) {
        // rangeEnd will have a time value of just before midnight
        const isInCoreRange = this.isWithinRange(fullDate, {
          from: this.coreDate,
          due: this.rangeEnd,
        });

        const flippingSelection = (
          (!this.selectingSecondDate && isAfter(fullDateForHover, this.rangeEnd)) ||
          (this.selectingSecondDate && isBefore(fullDateForHover, this.coreDate))
        );

        classList['range-member'] = isInCoreRange || ((this.isWithinRange(fullDate, {
          from: this.coreDate,
          due: fullDateForHover,
        })) && !flippingSelection);

        const sliceAndDiceAnchor = this.selectingSecondDate ? this.rangeEnd : this.coreDate;

        classList['slice-and-dice'] = (
          (
            this.isWithinRange(
              fullDate,
              {
                from: sliceAndDiceAnchor,
                due: fullDateForHover,
              },
            ) || isEqual(fullDate, sliceAndDiceAnchor)
          ) && !flippingSelection
        );

        if (isEqual(fullDateForHover, this.coreDate) || isEqual(fullDateForHover, this.rangeEnd)) {
          classList['slice-and-dice'] = false;
        }
      } else {
        // rangeEnd will have a time value of just before midnight
        classList['range-member'] = this.isWithinRange(rangeEndDate, {
          from: this.coreDate,
          due: this.rangeEnd,
        });
      }

      if (
        (isEqual(this.coreDate, fullDate) || isEqual(this.rangeEnd, rangeEndDate)) ||
        (this.hoveringOver === day && this.selectionStarted)
      ) {
        classList['range-edge'] = true;

        classList['range-member'] = false;
      }

      return classList;
    },

    getLabel(field = 'from'): String | null {
      if (this.label) {
        if (this.range) {
          if (field === 'from') {
            return this.label.from;
          } else {
            return this.label.due;
          }
        } else {
          return this.label;
        }
      }
    },

    grabPositioningData(): void {
      this.position.inputOne = this.$refs.inputOne.$el.getBoundingClientRect();

      if (this.range) {
        this.position.inputTwo = this.$refs.inputTwo.$el.getBoundingClientRect();
      }

      this.position.picker = this.$refs.picker.getBoundingClientRect();
    },

    hideList(): void {
      this.isOpen = false;

      removeTouchAndClickEventListenerToTarget(window, this.checkTouchOrClickTarget);
    },

    // Makes things a bit more semantic.
    // TODO: Rename the BaseSelect specific methods from the dropdownPositioningMixin to something general (CNNKN-2050)
    hideDatePicker(): void {
      this.hideList();
    },

    isWithinRange(day: Date, range: any): boolean {
      return (
        (isAfter(day, range.from) && isBefore(day, range.due)) ||
        (isBefore(day, range.from) && isAfter(day, range.due))
      );
    },

    nextMonth(): void {
      this.viewDate = addMonths(this.viewDate, 1);

      this.setViewData();
    },

    nextYear(): void {
      this.viewDate = addYears(this.viewDate, 1);

      this.setViewData();
    },

    async positionPicker(): Promise<any> {
      await this.$nextTick();

      this.grabPositioningData(); let direction;

      direction = this.position.picker.width > this.position.inputOne.width ? -1 : 1;

      this.$refs.picker.style.left = `${(Math.abs(this.position.picker.width - this.position.inputOne.width) / 2 * direction) + this.position.inputOne.left}px`;

      if (this.selectingSecondDate) {
        direction = this.position.picker.width > this.position.inputTwo.width ? -1 : 1;

        this.$refs.picker.style.left = `${(Math.abs(this.position.picker.width - this.position.inputTwo.width) / 2 * direction) + this.position.inputTwo.left}px`;
      }
    },

    previousMonth(): void {
      this.viewDate = subMonths(this.viewDate, 1);

      this.setViewData();
    },

    previousYear(): void {
      this.viewDate = subYears(this.viewDate, 1);

      this.setViewData();
    },

    select(day): void {
      const date = new Date(getYear(this.viewDate), getMonth(this.viewDate), day);

      if (!this.range) {
        this.$emit('input', date.toISOString());

        this.isOpen = false;

        return;
      }

      if (this.selectingSecondDate) {
        let selection = {
          from: null,
          due: null,
        };

        if (utIsEmpty(this.value.from)) {
          selection.due = endOfDay(date).toISOString();
        } else {
          if (isBefore(date, this.coreDate) && !utIsEmpty(this.value.due)) {
            selection.due = endOfDay(date).toISOString();
          } else {
            selection = this.setValidValuePair(endOfDay(date), this.coreDate);
          }
        }

        this.$emit('input', selection);

        this.closeAndReposition(selection);

        return;
      }

      let selection = {
        from: null,
        due: null,
      };

      if (utIsEmpty(this.value.due)) {
        selection.from = date.toISOString();
      } else {
        if (isAfter(date, this.rangeEnd) && !utIsEmpty(this.value.from)) {
          selection.from = date.toISOString();
        } else {
          selection = this.setValidValuePair(date, this.rangeEnd);
        }
      }

      this.$emit('input', selection);

      this.closeAndReposition(selection);
    },

    setValidValuePair(first: Date, second: Date): any {
      let value = {
        from: first.toISOString(),
        due: second.toISOString(),
      };

      if (isAfter(first, second)) {
        value = {
          from: second.toISOString(),
          due: first.toISOString(),
        };
      }

      return value;
    },

    setViewData(): void {
      this.firstDay = getISODay(startOfMonth(this.viewDate));

      this.numberOfDays = getDaysInMonth(this.viewDate);

      this.month = formatDate(this.viewDate, 'MMMM');

      this.year = formatDate(this.viewDate, 'yyyy');
    },

    showDatePicker(trigger): void {
      if (this.disabled) {
        return;
      }

      if (this.deviceIsIPad) {
        return;
      }

      this.selectingSecondDate = trigger === 2;

      if (!this.selectingSecondDate && !utIsEmpty(this.value?.from)) {
        this.viewDate = parseISO(this.value.from);
      }

      if (this.selectingSecondDate && !utIsEmpty(this.value?.due)) {
        this.viewDate = parseISO(this.value.due);
      }

      this.isOpen = true;

      this.positionPicker();

      addTouchAndClickEventListenerToTarget(window, this.checkTouchOrClickTarget);
    },

    checkTouchOrClickTarget(event): void {
      if (this.$refs.anchor === undefined || this.$refs.picker === undefined) {
        this.isOpen = false;

        return;
      }

      if (!(this.$refs.anchor.contains(event.target) || this.$refs.picker.contains(event.target))) {
        this.isOpen = false;
      }
    },
  },

  created(): void {
    this.debouncedPositionPicker = _debounce(
      this.positionPicker,
      DEBOUNCE_WAIT_RESIZE,
    );
  },

  beforeMount(): void {
    window.addEventListener('resize', this.debouncedPositionPicker);
  },

  mounted(): void {
    this.positionPicker();

    const container = this.$el;
    const list = this.$refs.picker;

    this.decoupleList(container, list);
  },

  beforeDestroy(): void {
    removeTouchAndClickEventListenerToTarget(window, this.checkTouchOrClickTarget);

    window.removeEventListener('resize', this.debouncedPositionPicker);
  },
});
