export const addTouchAndClickEventListenerToTarget = (target, functionReference) => {
  target.addEventListener('touchstart', functionReference);

  target.addEventListener('click', functionReference);
};

export const removeTouchAndClickEventListenerToTarget = (target, functionReference) => {
  target.removeEventListener('click', functionReference);

  target.removeEventListener('touchstart', functionReference);
};
